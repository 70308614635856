.logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;

  img {
    width: 140px;
  }
}

$primary-color-theme: #ca0a0a;

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Poppins-regular;
  src: url("./assets/fonts/WorkSans-Regular.ttf");
}
@font-face {
  font-family: Poppins-bold;
  src: url("./assets/fonts/WorkSans-Bold.ttf");
}
@font-face {
  font-family: Poppins-Medium;
  src: url("./assets/fonts/WorkSans-Medium.ttf");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins-regular;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.cursor-pointer {
  cursor: pointer;
}

nz-sider {
  // background-color: #ffffff !important;
  background-color: #000000 !important;
  color: $primary-color-theme !important;
}

.ant-menu {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  // color: #ffffff !important;
}

//for disabled select in whole web portal
body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
// scrollbar changes in whole site
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(202, 202, 202);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(162, 162, 162);
}

.large-form-modal {
  width: 60% !important;
}

nz-header {
  background: #fff;
  padding: 0;
}

nz-content {
  margin: 0 16px;
  height: calc(100vh - 64px) !important;
}

nz-breadcrumb {
  margin: 16px 0 !important;
}

.inner-content {
  padding: 15px;
  height: calc(100vh - 130px);
  overflow: auto;
  background: #fff;
  overflow: auto;
}

nz-footer {
  text-align: center;
}
.thead-primary thead {
  background-color: $primary-color-theme;
  color: #fff;
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.5rem;
}
.table {
  box-shadow: 0 1px 6px 1px rgb(69 65 78 / 10%);
  .action-btn {
    width: 160px;
    text-align: center;
  }
  thead tr th:last-child {
    width: 160px !important;
    text-align: center;
  }
  i {
    font-size: 18px;
    margin: 0 10px 0 0;
    cursor: pointer;
  }
  nz-switch {
    i {
      font-size: 14px !important;
      margin: 0 !important;
    }
  }
}
.badge-primary {
  background-color: $primary-color-theme;
}
.badge-success {
  background-color: #28a745;
}
.badge-dark {
  background-color: #34334a;
}
.table thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid hsl(0, 0%, 100%);
}
.ant-layout-sider-trigger {
  // background-color: #ffe7e6 !important;
  background: linear-gradient(
    90deg,
    #ca0a0a 0,
    #970808 100%,
    #970808 0
  ) !important;
  svg {
    color: #ffffff !important;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  background: transparent !important;
}

.ant-layout-header {
  // background-color: #ffffff !important;
  background: linear-gradient(90deg, #ca0a0a 0, #970808 100%, #970808 0);
}
.ant-layout-sider-children {
  box-shadow: 3px 0 8px -6px #888 !important;
}
.ant-layout-header {
  display: flex;
  justify-content: end;
  align-items: center;
  .right-icons {
    display: flex;
    i {
      margin: 0 0 0 25px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
.custom-btn {
  outline: none;
  border: none;
  border: 1px solid $primary-color-theme;
  color: $primary-color-theme;
  padding: 7px 15px;
  background: transparent;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 5px;
  &:hover {
    background: #ffe7e6;
    color: $primary-color-theme;
    border-color: #ffe7e6 !important;
  }
  p {
    margin: 0;
    i {
      font-size: 17px;
      margin-right: 5px;
    }
  }
}

.cancel-btn {
  border: 1px solid #333333;
  color: #333333;
  &:hover {
    background: #333333 !important;
    color: #fff;
    border-color: #ffe7e6 !important;
  }
}
.validation-message {
  color: #ca0a0a;
  font-size: 13px;
  margin: 5px 0;
}
// custom input box

.form-group {
  margin-bottom: 15px;
}
.custom-input {
  width: 100%;
  padding: 5px 15px;
  font-size: 13px;
  border: 1px solid #e7e7e7;
  &:focus {
    outline: none;
    border-color: #888;
  }
}
nz-select {
  border: 1px solid #e7e7e7;
  &:focus {
    outline: none;
    border-color: #888;
  }
}
.mr-10 {
  margin-right: 10px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// open drawer icon
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 10px;
  right: -50px;
  z-index: 1;
  width: 53px;
  height: 42px;
  font-size: 57px;
  color: #000 !important;
  font-size: 30px !important;
  font-weight: bold !important;
  /* line-height: 42px; */
  text-align: center;
  background: transparent;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}

// nz-breadcrumb-item css

nz-breadcrumb-item {
  cursor: pointer;
  i {
    font-size: 20px;
    margin-right: 5px;
    svg {
      margin-top: -6px;
    }
  }
}
//red border
.red-border {
  border: 1px solid #ca0a0a;
}
// Block Common Color
.block-icon-color {
  color: $primary-color-theme !important;
}

//nz avtar css
nz-avatar {
  background-color: #ffe7e6 !important;
  color: $primary-color-theme !important;
  cursor: pointer;
}
.ant-popover-title {
  padding: 10px 20px;
}
.ant-popover-inner-content {
  padding: 12px 10px !important;
  .extra-menu {
    li {
      padding: 5px 10px;
      display: flex;
      border-radius: 5px;
      align-items: center;
      cursor: pointer;
      i {
        margin-right: 10px;
      }
      &:hover {
        background-color: #ffe7e6 !important;
        color: $primary-color-theme !important;
      }
    }
  }
}

.ant-input:hover {
  border-color: #cccccc;
  border-right-width: 1px !important;
}
  
  .ant-input:focus, .ant-input-focused{
    border-color:none !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow:none !important;
}


